import React from 'react';
import styles from './footer.module.css';

function Footer() {
    return (
        <section className={styles.footer}>
            <div className={styles.content}>
                <p alt="brainstorm.ai">&copy; {new Date().getFullYear()} Alonso Peralta & Stephen Monahan. All rights reserved.</p>
                <div className={styles.row}>
                    <a className={styles.link} href="https://alonsoperalta.com" alt="Alonso Peralta">alonsoperalta.com</a>
                    <a className={styles.link} href="https://stephenswe.com" alt="Stephen Monahan">stephenswe.com</a>
                </div>
            </div>
        </section>
    );
}

export default Footer;
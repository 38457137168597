import React, { useState } from 'react';
import styles from './App.module.css';
import Header from './components/header/header.js';
import ChatBox from './components/chatbox/chatbox.js';
import Eyes from './components/boteyes/boteyes.js';
import Footer from './components/footer/footer.js';
import light from './assets/light.png'; 

function App() {
  return (
    <section className={styles.app}>
      <img src={light} className={styles.light} />
      <div className={styles.landing}>
        <Header />
        <Eyes />
      </div>
      <div className={styles.cone} />
      {/* <div className={styles.floor} /> */}
      <div className={styles.content}>
        <ChatBox />
      </div>
      <Footer />  
    </section>
  );
}

export default App;

import React, { useState } from 'react';
import styles from './chatbox.module.css';
import axios from 'axios';
import UploadBox from '../upload/upload.js';

function ChatBox() {
  const [messages, setMessages] = useState([
    {
      message: "What idea do you have in mind?",
      sentTime: "just now",
      sender: "ChatGPT"
    }
  ]);
  const [input, setInput] = useState('');

  const handleSend = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const newMessage = {
      message: input,
      direction: 'outgoing',
      sender: "user"
    };

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setInput('');

    try {
      const response = await axios.post('https://us-central1-brainstormai-d30db.cloudfunctions.net/api/chat', { message: input });
      const botMessage = { sender: "ChatGPT", message: response.data.response };
      setMessages([...newMessages, botMessage]);
    } catch (error) {
      console.error('Error:', error);
      const errorMessage = { sender: "ChatGPT", message: "Something went wrong. Please try again." };
      setMessages([...newMessages, errorMessage]);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleUploadResponse = (message) => {
    const newMessages = [...messages, { sender: "ChatGPT", message }];
    setMessages(newMessages);
  };

  return (
    <section className={styles.container}>
      <div className={styles.chatBox}>
        <div className={styles.messages}>
          {messages.map((message, i) => (
            <div key={i} className={message.sender === 'user' ? `${styles.userMessage}` : `${styles.botMessage}`}>
              {message.message.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
          ))}
        </div>
        <form className={styles.inputForm} onSubmit={handleSend}>
          <textarea
            name="input"
            placeholder="What skills you want to learn through project-based learning?"
            value={input}
            onChange={handleInputChange}
            className={styles.input}
            rows="1"
          />
          <button type="submit" className={styles.button}>Send</button>
          <UploadBox onUploadResponse={handleUploadResponse} />
        </form>
      </div>
    </section>
  );
}

export default ChatBox;

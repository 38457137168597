import React, { useState, useEffect } from 'react';
import styles from './boteyes.module.css';

const Eyes = () => {
  const [mousePosition, setMousePosition] = useState({ x: window.innerWidth / 2, y: window.innerHeight / 2 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  const calculatePupilPosition = (eyeCenterX, eyeCenterY) => {
    const dx = mousePosition.x - eyeCenterX;
    const dy = mousePosition.y - eyeCenterY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const maxDistance = 7.5; // Max distance the pupil can move within the eye

    if (distance < maxDistance) {
      return { transform: `translate(${dx}px, ${dy}px)` };
    } else {
      const angle = Math.atan2(dy, dx);
      const pupilX = maxDistance * Math.cos(angle);
      const pupilY = maxDistance * Math.sin(angle);
      return { transform: `translate(${pupilX}px, ${pupilY}px)` };
    }
  };

  return (
    <div className={styles.face}>
      <div className={styles.eye}>
        <div className={styles.pupil} style={calculatePupilPosition(window.innerWidth / 2 - 25, window.innerHeight / 2)}></div>
      </div>
      <div className={styles.eye}>
        <div className={styles.pupil} style={calculatePupilPosition(window.innerWidth / 2 + 25, window.innerHeight / 2)}></div>
      </div>
    </div>
  );
};

export default Eyes;









import React, { useEffect, useState } from 'react';
import styles from './header.module.css';

function Header() {
    const [text, setText] = useState('');
    const fullText = "Brainstorm.ai";

    useEffect(() => {
        let currentIndex = 0;
        const intervalId = setInterval(() => {
            setText(fullText.substring(0, currentIndex + 1));
            currentIndex++;
            if (currentIndex >= fullText.length) {
                clearInterval(intervalId);
            }
        }, 100); // Adjust typing speed here

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <section className={styles.header}>
            <h3>
                {text}
                <span className={styles.cursor}>|</span>
            </h3>
        </section>
    );
}

export default Header;




import React, { useState, useRef } from 'react';
import styles from './upload.module.css';

export const UploadBox = ({ onUploadResponse }) => {
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('file', selectedFile);
  
            const response = await fetch('https://us-central1-brainstormai-d30db.cloudfunctions.net/api', { // Corrected URL
                method: 'POST',
                body: formData,
            });
  
            if (!response.ok) {
                const result = await response.json();
                throw new Error(result.error || 'Failed to process file on the server');
            }
  
            const result = await response.json();
            onUploadResponse(result.message);
        } catch (error) {
            console.error('Error uploading file:', error);
            onUploadResponse(`Failed to upload file. ${error.message}`);
        } finally {
            setLoading(false);
        }
    } else {
        onUploadResponse('Please upload a PDF file');
    }
  };
  

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  return (
    <section className={styles.container}>
      <div className={styles.uploadContainer}>
        <input
          className={styles.upload}
          type="file"
          accept="application/pdf"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
        <div
          className={styles.uploadDiv}
          onClick={handleUploadClick}
          role="button"
          tabIndex="0"
          onKeyPress={(e) => e.key === 'Enter' && handleUploadClick()}
          disabled={loading}
        >
          {loading ? 'Uploading...' : 'Upload Resume'}
        </div>
      </div>
    </section>
  );
};

export default UploadBox;
